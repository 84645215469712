<template>
  <div>
    <div v-if="recspec === true">
      <div
        v-for="(entry,index) in Object.entries(RequirementSpecification).slice(0,8)"
        :key="entry[0]"
        class="flex flex-col mt-8 mb-4 box-shadow-radius w-full indicator height_qn"
      >
        <div class="w-full table-heder-background-color drop-shadow-md rounded-tr-md rounded-tl-md">
          <h6 class="font-semibold text-xl leading-7 pt-8 color-gray pr-8 pl-8">
            {{ entry[0] }}
          </h6>
          <p class="text-base font-normal pb-8 text-slate-500 mt-1 pr-8 pl-8 mt-4 leading-normal">
            {{ entry[1] }}
          </p>
        </div>
        <div class="flex xs:flex-col sm:flex-col md:flex-row justify-between bg-white mobile-view-cols">
          <div class="w-1/2 mt-6 mb-6 pr-0 md:pr-12 mobile-width-view pl-0 md:pl-8 ">
            <p class="text-base font-normal text-slate-500 leading-9">
              How frequently does situations occur where this ability is needed?
            </p>
            <slider-input
              type="reqspec"
              class="self-center mt-12 mb-6"
              v-model.number="requirements[indexMap[index][0]]"
              style="max-width: 650px"
            />
          </div>
          <div class="w-1/2 mt-6 mb-6 pr-0 md:pr-12 mobile-width-view pl-0 md:pl-12 ">
            <p class="text-base font-normal text-slate-500 leading-8">
              How important is this ability?
            </p>
            <br
              class=""
            >
            <slider-input
              class="self-center mt-12 mb-6"
              type="importance"
              v-model.number="requirements[indexMap[index][1]]"
              style="max-width: 650px;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderInput from '../components/SliderInput.vue'
import { RequirementSpecification } from '@/content/reportContent.json'

export default {
  components: {
    SliderInput
  },
  props: {
    highLowDesc: {
      type: Array,
      required: false,
      default: null
    },
    requirements: {
      type: Array,
      required: true
    },
    highlight: {
      type: Boolean
    },
    rateBetween: {
      type: Boolean
    },
    questionDropdown: {
      type: String,
      required: false,
      default: null
    },
    recspec: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      lsh: 4,
      ds: 2,
      RequirementSpecification,
      indexMap: [
        [0, 1],
        [2, 3],
        [4, 5],
        [6, 7],
        [8, 9],
        [10, 11],
        [12, 13],
        [14, 15]
      ]
    }
  }
}
</script>

<style scoped>

.color1 {
  background-color: rgb(243, 247, 251);
}
.color2 {
  background-color: rgb(248, 251, 253)
}

.color1_op {
  background-color: rgb(243, 247, 251, 0.6);
}
.color2_op {
  background-color: rgb(248, 251, 253, 0.6)
}

.height_qn {
   max-width: 950px;
}

.height_rb {
   width: 100%;
}
.badge{
  height: 1.9rem !important;
  line-height: 2rem !important;
}
@media (min-width: 1024px) {
    .height_qn {
        width: 1200px;
    }
     .height_qrb {
        width: 24rem;
    }
}

</style>
