<template>
  <div class="w-full h-full">
    <div
      class="grid md:grid-cols-3 grid-cols-1  h-96  bg-green-main"
    >
      <!-- <div class="self-start w-full text-white justify-self-start" /> -->
      <div
        class=" flex-col justify-center items-center hidden md:flex"
        style="width: 200px; height:130px"
      >
        <img
          src="@/assets/capacio/login/logo.png"
          class="h-16 w-64"
        >
      </div>
      <div class="flex flex-col items-center pt-0 justify-center">
        <div

          class="height_hd"
        >
          <h1
            ref="title"
            class="text-xl text-center text-white lg:text-left lg:text-4xl"
          >
            {{ title }}
          </h1>
          <p class="mt-5 text-sm text-center text-white lg:text-left">
            {{ description }}
          </p>
          <br>
          <div
            v-if="sources"
            class="text-xs text-white"
          >
            <b>Refrences:</b> <ol>
              <li
                v-for="(source, index) in sources"
                :key="index"
                class="list-decimal list-inside"
              >
                {{ source }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-light">
      <div
        class="flex flex-col items-center px-1 space-y-20 bg-primary-light"
        v-if="!rec_spec"
      >
        <question
          v-for="(question,index) in questions"
          :key="index"
          :ref="`q_${index}`"
          v-model="answers[index]"
          :index="index + 1"
          :gray="(index % 2 === 0) ? true : false"
          :question-text="question"
          :scales="scales"
          :rate-between="($route.params.type === 'IRI' || $route.params.type === 'GENERAL_AESC') ? true : false"
          :highlight="answers[index] === null && submited "
          @input="scrollToQ(index +1)"
          :question-dropdown="getQuestionDropdownText(question)"
          :recspec="rec_spec"
        />
      </div>
      <div
        class="flex flex-col md:items-center px-1 space-y-20 bg-primary-light"
        v-if="rec_spec"
      >
        <req-spec
          :recspec="rec_spec"
          :requirements="requirements"
        />
      </div>
      <div
        v-if="this.$store.getters['QUESTIONNAIRE/getQuestionnaireList'].length > 1"
        class="flex justify-center p-7"
      >
        <button
          class="w-56 btn bg-green-main"
          @click="submit"
        >
          <span v-if="$store.state.QUESTIONNAIRE.lang === 'EN'">Continue</span>
          <span v-else>Fortsätt</span>
        </button>
      </div>
      <div
        v-else
        class="flex justify-center p-7"
      >
        <button
          class="w-56 btn bg-green-main"
          @click="submit"
        >
          <span v-if="$store.state.QUESTIONNAIRE.lang === 'EN'">Submit</span>
          <span v-else>Lämmna in</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Question from '../components/Question.vue'
import ReqSpec from './ReqSpec.vue'
import { RequirementSpecification } from '@/content/reportContent.json'
import '../questionnaire.scss'
export default {
  components: { Question, ReqSpec },
  data () {
    return {
      answers: [],
      questions: [],
      title: '',
      description: '',
      scales: [],
      sources: [],
      submited: false,
      requirementSpecification: false,
      RequirementSpecificationText: RequirementSpecification,
      rec_spec: false,
      reqSpecQuestion: [],
      requirements: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    }
  },

  watch: {
    // eslint-disable-next-line
    async $route(to, from) {
      // reacts when qustionnaire type changes
      const type = to.params.type
      this.$store.commit('QUESTIONNAIRE/UPDATE_INDEX', this.$store.getters['QUESTIONNAIRE/getQuestionnaireList'].findIndex(el => el === type))

      await this.init(type)
    }
  },
  async mounted () {
    const type = this.$route.params.type
    this.init(type)

    if (type === 'REQ_SPEC') {
      this.rec_spec = true
    }
  },
  methods: {
    scrollToQ (index) {
      if (index >= this.questions.length) return

      const [{ $el }] = this.$refs[`q_${index}`]

      $el.scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
    async submit () {
      this.submited = true // used for highlighting unanswered questions

      if (this.$route.params.type === 'REQ_SPEC') {
        const answerdAll = this.requirements.every(el => el) // if one is unanswered this returns false
        if (!answerdAll) return
        // post answers for this type to the backend
        await this.$store.dispatch('QUESTIONNAIRE/submitResults', this.requirements)
      } else {
        const answerdAll = this.answers.every(el => el) // if one is unanswered this returns false
        if (!answerdAll) return

        // post answers for this type to the backend
        await this.$store.dispatch('QUESTIONNAIRE/submitResults', this.answers)
      }

      if (this.$store.state.QUESTIONNAIRE.currentQuestionIndex + 1 === this.$store.getters['QUESTIONNAIRE/getQuestionnaireList'].length) {
        this.$router.push('/questionnaire/finished')
      } else {
        // gets next quesstionnaire type and router push it.
        this.$router.push(`/questionnaire/${this.$store.getters['QUESTIONNAIRE/getQuestionnaireList'][this.$store.state.QUESTIONNAIRE.currentQuestionIndex + 1]}`)
      }
    },
    getQuestionDropdownText (questionText) {
      if (!this.requirementSpecification) {
        return null
      }

      const keys = Object.keys(this.RequirementSpecificationText)
      const keysTransformed = keys.map(key => key.toLowerCase())
      const index = keysTransformed.findIndex(key => questionText.includes(key))

      return this.RequirementSpecificationText[keys[index]]
    },
    async init (type) {
      // get type questions
      const data = await this.$api.getQuestionnaireConfig(type, this.$store.state.QUESTIONNAIRE.lang)

      // updates ui
      this.description = data.description
      this.sources = data.sources
      this.title = data.name
      this.scales = data.scales

      this.questions = data.questionList
      // empties answers
      this.answers = new Array(this.questions.length).fill(null)
      this.submited = false

      // checks for the "REQ_SPEC" type. This to insert custom dropdowns when hovering the questions.
      if (type === 'REQ_SPEC') {
        this.requirementSpecification = true
        this.rec_spec = true
        this.title = 'Cognitive Job Requirements'
        this.description = 'Do your best to estimate the cognitive requirements on your assignment.'
      }
      // scrolls to top of page
      this.$refs.title.scrollIntoView({ block: 'center' })
    }
  }

}
</script>

<style scoped>
.height_hd {
   max-width: 900px;
}

@media (min-width: 1024px) {
    .height_hd {
        width: 900px;
    }
}

</style>
